var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"title",style:({
    'background-image': `url(${require('@/assets/' +
      _vm.typeOf +
      '/' +
      _vm.njTitle +
      '.png')})`
  })},[_c('div',{staticClass:"title-desc"},[_vm._v(" "+_vm._s(_vm.title)+" ")]),_c('div',{staticClass:"rightBgc anniu3",on:{"click":_vm.toback}},[_c('img',{staticClass:"imgBox",attrs:{"src":require("../../../assets/njfwimgs/quanping.png")}}),_c('p',[_vm._v("全屏")])])])
}
var staticRenderFns = []

export { render, staticRenderFns }