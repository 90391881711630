import request from '@/utils/request.js';
// import axios from 'axios';
//获取农田总面积
export function getNtmj(params) {
  return request({
    url: '/field/getTotalArea',
    method: 'get',
    params,
  });
}
//获取农田总面积
export function getNtjbqk(params) {
  return request({
    url: '/field/getFieldBasicSituation',
    method: 'get',
    params: params,
  });
}
// /field/getAgriculture?pageNum=1&pageSize=10
//获取农业主体柱状图数据
export function getLsgnq(params) {
  return request({
    url: '/field/getAgriculture',
    method: 'get',
    params: params,
  });
}


//获取大屏气象显示信息
export function getQxdp(data,params) {
  return request({
    url: `/air/getAirByDeviceId/${ data }`,
    method: 'get',
    params: params,
  });
}
//获取墒情信息
export function getSqxq(data,params) {
  return request({
    url: `/soilTilth/getByDeviceId/${ data }`,
    method: 'get',
    params: params,
  });
}

//获取虫害信息
export function getChxx(params) {
  return request({
    url: '/pest/getPestAndDiseaseInfo',
    method: 'get',
    params: params,
  });
}
//获取农田总种植补贴
export function getZzbt(params) {
  return request({
    url: '/field/getTotalSubsidy',
    method: 'get',
    params: params,
  });
}
//获取农田数量及详情
export function getNtsl(params) {
  return request({
    url: '/field/getAll',
    method: 'get',
    params: params,
  });
}

//获取农业标准地数据
export function getStandardPlot(params) {
  return request({
    url: '/field/getStandardPlot',
    method: 'get',
    params: params,
  });
}

//获取农田基本情况（新）
export function fieldBasic(params) {
  return request({
    url: '/fieldBasic/list',
    method: 'get',
    params: params,
  });
}
//获取农田补贴详情
export function getNtbt(params) {
  return request({
    url: '/field/getSubsidyAll',
    method: 'get',
    params: params,
  });
}
//获取气象曲线图数据
export function getQxqxt(params) {
  return request({
    url: '/air/getAirGraph',
    method: 'get',
    params: params,
  });
}
//获取气象历史信息
export function getAirHistory(params) {
  return request({
    url: '/air/getAirHistory',
    method: 'get',
    params: params,
  });
}
//获取虫害曲线图数据
export function getPestGraph(params) {
  return request({
    url: '/pest/getPestGraph',
    method: 'get',
    params: params,
  });
}
//获取虫害历史数量及比率
export function getPestHistory(params) {
  return request({
    url: '/pest/getPestHistory',
    method: 'get',
    params: params,
  });
}
//根据监测点名称获取苗情信息 seedlings/getSeedlingsByName/苗情监测点1
export function getSeedlingsByName(params) {
  return request({
    url: `/seedlings/getSeedlingsByName/${ params }`,
    method: 'get',
  });
}
//获取虫情监测名称和设备id
export function getPestMonitoringNames(params) {
  return request({
    url: '/pestMonitoring/getPestMonitoringNames',
    method: 'get',
    params: params,
  });
}
//根据监测点名称获取虫情监测信息  /pestMonitoring/getPestMonitoringByName/虫情监测点2
export function getPestMonitoringByName(data,params) {
  return request({
    url: `/pestMonitoring/getPestMonitoringByDeviceId/${ data }`,
    params: params,
    method: 'get',
  });
}

//获取所有土壤监测点名称
export function getSoilNames(params) {
  return request({
    url: '/soilTilth/getSoilNames',
    method: 'get',
    params: params,
  });
}
//获取所有长势监测点名称
export function getSeedlingNames(params) {
  return request({
    url: '/seedlings/getSeedlingNames',
    method: 'get',
  });
}
//根据监测点名称查询长势历史 /seedlings/getSeedlingsHistory?seedlingName=长势监测点1&begin=2023-08-25&end=2023-09-10
export function getSeedlingsHistory(params) {
  return request({
    url: '/seedlings/getSeedlingsHistory',
    method: 'get',
    params: params,
  });
}
//根据监测点设备id查询虫情监测历史
export function getPestMonitoringHistory(params) {
  return request({
    url: '/pestMonitoring/getPestMonitoringHistory',
    method: 'get',
    params: params,
  });
}

// 获取气象日志信息曲线图数据 47.96.146.179:8081/air/getAirDateLog
export function getAirDateLog(params) {
  return request({
    url: '/air/getAirDateLog',
    method: 'get',
    params: params,
  });
}// 获取水质信息
export function getWaterQuality(params) {
  return request({
    url: '/waterQuality/getWaterQuality',
    method: 'get',
    params: params,
  });
}
//获取土壤日志数据
export function getLogAll(params) {
  return request({
    url: '/soilDateLog/getLogAll',
    method: 'get',
    params: params,
  });
}
//获取虫害告警信息
export function getPestWarning(params) {
  return request({
    url: '/pest/getPestWarning',
    method: 'get',
    params: params,
  });
}
//获取气象站名称和设备id
export function getAirNames(params) {
  return request({
    url: '/air/getAirNames',
    method: 'get',
    params: params,
  });
}
//获取所有设备
export function device(params) {
  return request({
    url: '/device/get',
    method: 'get',

  });
}
//获取告警信息
export function getAlarmInfo(params) {
  return request({
    url: '/alarm/getAlarmInfo',
    method: 'get',
    params: params,
  });
}
//根据父级id查询所有子级数据
export function getUserByParentId(params) {
  return request({
    url: '/user/getUserByParentId',
    method: 'get',
    params: params
  });
}
//查询设备id及设备名
export function getIdName(params) {
  return request({
    url: '/device/getIdName',
    method: 'get',
    params: params
  });
}
