<!--  -->
<template>
    <div class='right'>
      
        <div class="title">虫情信息</div>
        <div class="cqXx">
            <div class="lbt">
                <el-carousel :initial-index="0" @change="((pre, next) => { carousel1(pre, next) })"
                    indicator-position="none" height="17vh" :interval="5000" arrow="always">
                    <el-carousel-item v-for="(item, index) in mqData2" :key="index">
                        <!-- <h3>{{ item }}</h3> -->
                        <div class="img-box">
                            <div class="img-box-item">
                                <img class="img-box-item-img" :src="item[0].monitoringPicture"></img>
                                <div class="img-box-item-time">{{ item[0].monitoringDate }}</div>
                            </div>
                            <div class="img-box-item">
                                <img class="img-box-item-img" v-if="item.length > 1" :src="item[1].monitoringPicture"></img>
                                <div class="img-box-item-time" v-if="item.length > 1">{{ item[1].monitoringDate }}</div>
                            </div>
                        </div>
                    </el-carousel-item>
                </el-carousel>
                <div class="btm-progress">
                    <el-progress stroke-width="5" color="#65ebca" :percentage="percentage2" status="success">
                    </el-progress>
                </div>
            </div>
        </div>
        <div class="title">虫情类型</div>
        <div class="cqlx">
            <div class="botton-box">
                <div class="bar">
                    <div class="title-echarts">病虫害种类及数量</div>
                    <Echart :option="option"></Echart>
                </div>
            </div>
        </div>
        <div class="title">实时预警</div>
        <div class="ssYj">
            <div class="yjTitle">
                <div class="label">时间</div>
                <div class="label">警告位置</div>
                <div class="label">警告内容</div>
            </div>
            <vue-seamless-scroll :data="yjData" class="seamless-warp" :class-option="classOption" v-if="yjData&&yjData.length > 0">
                <ul class="scrollbox item">
                    <li class="scrollItem" v-for="(item, index) in yjData" :key="item.id">
                        <div class="label">{{ item.alarmTime }}</div>
                        <div class="label">{{ item.alarmPosition }}</div>
                        <div class="label " style="color: red;">{{ item.alarmContent }}</div>
                    </li>
                </ul>
            </vue-seamless-scroll>
        </div>
    </div>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';
import Echart from "../../../components/echarts/index.vue";
import { getChxx } from '../../../api/numberApi/index';
import vueSeamlessScroll from 'vue-seamless-scroll'
export default {
    //import引入的组件需要注入到对象中才能使用
    components: {
        Echart,
        vueSeamlessScroll
    },
  
    data() {
        //这里存放数据
        return {
            cqXx: '',
            percentage2: 0,
           
            option: {
                xAxis: {
                    type: "category",
                    data: [],
                    axisLabel: {
                        textStyle: {
                            color: "#fff",
                        },
                        interval: 0,
                        rotate: -20,
                    },
                },
                grid: {
                    height: "65%",
                    width: "100%",
                    left: "8%",
                    right: "4%",
                    // bottom: "10%",
                    top: '13%',
                    containLabel: true,
                },
                yAxis: {
                    type: "value",
                    minInterval: 10,
                    axisLabel: {
                        textStyle: {
                            color: "#fff",
                        },
                    },
                    splitLine: {
                        show: true,
                        lineStyle: {
                            color: "#A8A8A8",
                            opacity: 0.4,
                        },
                    },
                },
                series: [
                    {
                        data: [],
                        type: "bar",
                        showBackground: true,
                        color: "#3DFCCD",
                        barWidth: 5,
                        showBackground: false,
                        backgroundStyle: {
                            color: "rgba(180, 180, 180, 0.2)",
                        },
                        itemStyle: {
                            //上方显示数值
                            normal: {
                                label: {
                                    show: true, //开启显示
                                    position: "top", //在上方显示
                                    textStyle: {
                                        //数值样式
                                        color: "rgba(57, 255, 209, 1)",
                                        fontSize: 16,
                                    },
                                },
                            },
                        },
                    },
                ],
            },
        };
    },
    props: {
        downList3: [],
        mqData2: [],
        yjData:[],
        tenantId:0
    },

    //监听属性 类似于data概念
    computed: {
        classOption() {
            return {
                step: 1, // 数值越大速度滚动越快
                limitMoveNum: 4, // 开始无缝滚动的数据量 this.dataList.length
                hoverStop: true, // 是否开启鼠标悬停stop
                direction: 1, // 0向下 1向上 2向左 3向右
                openWatch: true, // 开启数据实时监控刷新dom
                singleHeight: 0, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
                singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
                waitTime: 1000 // 单步运动停止的时间(默认值1000ms)
            }
        }

    },
    //监控data中的数据变化
    watch: {
        // tenantId:{
        //     handler(old,newData){
        //         console.log(111);
        //         if(newData){
                   
        //             this.getData()
        //         }
               
        //     },
        //     deep:true
        // }
    },

    //方法集合
    methods: {
        downClick(el, pr) {
            this.cqXx = el.label
            this.$listeners.getCqXx(el.value)
        },
        //轮播图1切换时
        carousel1(e, n) {
            this.percentage2 = ((e + 1) / this.mqData2.length) * 100
        },
        getData(tenantId) {
            getChxx({tenantId:tenantId}).then(res => {
                if (res.code == 1) {
                    this.option.xAxis.data = res.data.pestLevel.split(',')

                    if (res.data.pestQuantity.length) {
                        this.option.series[0].data = res.data.pestQuantity.split(',')
                    }
                }
            })
        }
    },
    //生命周期 - 创建完成（可以访问当前this实例）
    created() {

    },
    //生命周期 - 挂载完成（可以访问DOM元素）
    mounted() {


        // this.getData()
    },
    beforeCreate() { }, //生命周期 - 创建之前
    beforeMount() { }, //生命周期 - 挂载之前
    beforeUpdate() { }, //生命周期 - 更新之前
    updated() { }, //生命周期 - 更新之后
    beforeDestroy() { }, //生命周期 - 销毁之前
    destroyed() { }, //生命周期 - 销毁完成
    activated() { }, //如果页面有keep-alive缓存功能，这个函数会触发
}
</script>
<style lang='scss' scoped>
//@import url(); 引入公共css类
@function companyW($num) {
    @return ($num * 100)/1920;
}

@function companyH($num) {
    @return ($num * 100)/1080;
}

.right {
    width: companyW(480vw);
    height: companyH(909vh);
    padding: companyH(25vh) companyW(22vw);
    background-image: url('../../../assets/overviewImg/biankuang.png');
    background-size: 100% 100%;
    background-repeat: no-repeat;

    .select {
        display: flex;
        justify-content: right;
        margin-bottom: companyH(20vh);
    }

    .title {
        width: companyW(480vw);
        color: #fff;
        font-weight: bold;
        font-size: companyW(22vw);
        text-align: center;
        background-image: url('./../../../assets/overviewImg/title.png');
        background-size: 50% 100%;
        background-position: 50% 0;
        background-repeat: no-repeat;
        margin: companyH(20vh) 0;
    }

    .cqXx,

    .cqlx {
        border: 1px solid #01C696;
        padding: companyW(10vw);
    }

    .botton-box {
        overflow: hidden;
        width: 100%;
        height: companyH(220vh);
        display: flex;
        flex-wrap: nowrap;
        justify-content: space-between;

        .bar {
            width: companyW(430vw);
            height: companyH(200vh);
        }

        .pie {
            width: companyW(210vw);
            height: companyH(170vh);
        }
    }

    .title-echarts {
        margin: companyH(10vh) 0;
        text-align: center;
        color: #fff;
        font-size: companyH(18vh);
    }

    .ssYj {
        border: 1px solid #01C696;
        height: companyH(220vh);
    }

    .yjTitle {
        height: companyH(30vh);
        line-height: companyH(30vh);
        padding: 0 companyW(10vw);
        color: #fff;
        font-weight: bold;
        background-color: rgba(1, 198, 150, .3);
        display: flex;
        justify-content: space-between;
        font-size: companyH(17vh);
        margin-bottom: companyH(6vh);
    }

    .seamless-warp {
        height: companyH(150vh);
        overflow: hidden;
    }

    .scrollbox {
        .scrollItem {
            height: companyH(30vh);
            line-height: companyH(30vh);
            padding: 0 companyW(10vw);
            color: #fff;
            font-weight: bold;
            background-color: rgba(1, 198, 150, .2);
            display: flex;
            justify-content: space-between;
            font-size: companyH(17vh);
            margin-bottom: companyH(6vh);
        }
    }
}

.el-dropdown-link {
    font-size: companyH(18vh);
    cursor: pointer;
    color: #fff;
    height: companyH(25vh);
    line-height: companyH(25vh);
    font-size: companyW(16vw);
    font-weight: bold;
    border: 1px solid #01C696;
    padding: companyH(7vh) companyW(20vw);
}

.el-icon-arrow-down {
    margin-left: companyW(10vw);
    font-weight: bold;
    color: rgb(2, 163, 122);
    font-size: 12px;
}

::v-deep .popper-select {
    position: absolute !important;
    top: 35px !important;
    left: 0px !important;
}

.lbt {
    width: 100%;
    height: companyH(220vh);

    // background-color: #fff;
    .img-box {
        top: companyH(30vh);
        width: 100%;
        height: companyH(250vh);
        display: flex;
        justify-content: space-around;

        .img-box-item {
            .img-box-item-img {
                margin-top: companyH(20vh);
                width: companyW(200vw);
                height: companyH(120vh);
            }

            .img-box-item-time {
                margin-top: companyH(10vh);
                text-align: center;
                font-size: companyH(10vh);
                color: #fff;
            }
        }
    }

    .btm-progress {
        padding-left: companyW(40vw);
        width: companyW(360vw);
        height: companyH(20vh);
    }
}
</style>