<!--  -->
<template>
    <div class='left'>
        <div class="select">
            <div class="selectBox">
                <el-dropdown @command="((el, pr) => { downClick(el, pr) })">
                    <span class="el-dropdown-link">
                        {{ qxXx ? qxXx : downListQx.length ? downListQx[0].tenantName : '' }}<i
                            class="el-icon-arrow-down el-icon-caret-bottom"></i>
                    </span>
                    <el-dropdown-menu slot="dropdown">
                        <el-dropdown-item v-for="(item, index) in downListQx" :key="index" :command="item">{{ item.tenantName
                        }}</el-dropdown-item>
                    </el-dropdown-menu>
                </el-dropdown>
            </div>
        </div>
        <div class="title">气象信息</div>
        <div class="qxxx">
            <div class="box">
                <div class="box-item" @click="openChart('kqwd', rightData.qxData.id, 1)">
                    <div class="icons img1"></div>
                    <div class="number">{{ rightData.qxData.airTemperature ? rightData.qxData.airTemperature.toFixed(2) : '0'
                    }}℃
                    </div>
                    <div class="text">空气温度</div>
                </div>
                <div class="box-item " @click="openChart('kqsd', rightData.qxData.id, 1)">
                    <div class="icons img2"></div>
                    <div class="number">{{ rightData.qxData.airHumidity?rightData.qxData.airHumidity:'0' }}</div>
                    <div class="text">空气湿度</div>
                </div>
                <div class="box-item" @click="openChart('gzqd', rightData.qxData.id, 1)">
                    <div class="icons img3"></div>
                    <div class="number">{{ rightData.qxData.airLight?rightData.qxData.airLight:'0' }}</div>
                    <div class="text">光照强度</div>
                </div>
                <div class="box-item" @click="openChart('jsl', rightData.qxData.id, 1)">
                    <div class="icons img4"></div>
                    <div class="number">{{ rightData.qxData.airPrecipitation ? rightData.qxData.airPrecipitation.toFixed(2)
                        : '0'
                    }}mm</div>
                    <div class="text">降水量</div>
                </div>
                <div class="box-item">
                    <div class="icons img5"></div>
                    <div class="number">{{ rightData.qxData.airSpeed ? rightData.qxData.airSpeed.toFixed(2) : '0' }}m/s</div>
                    <div class="text">风速</div>
                </div>
                <div class="box-item ">
                    <div class="icons img6"></div>
                    <div class="number">{{ rightData.qxData.airDirection?rightData.qxData.airDirection:'\u3000' }}</div>
                    <div class="text">风向</div>
                </div>
                <div class="box-item">
                    <div class="icons img7"></div>
                    <div class="number">{{ rightData.qxData.airPressure ? rightData.qxData.airPressure.toFixed(2) : '0' }}Pa
                    </div>
                    <div class="text">大气压</div>
                </div>
                <div class="box-item ">
                    <div class="icons img8"></div>
                    <div class="number">{{ rightData.qxData.airPower?rightData.qxData.airPower:'0' }}</div>
                    <div class="text">风力</div>
                </div>
            </div>
        </div>
        <div class="title">墒情信息</div>
        <div class="sqxx">
            <div class="box">
                <div class="box-item" @click="openChart('trwd', rightData.sqxqData.deviceId, 2)">
                    <div class="icons img9"></div>
                    <div class="number">{{
                        rightData.sqxqData.soilTemperature ? rightData.sqxqData.soilTemperature.toFixed(2) : '0' }}℃</div>
                    <div class="text">土壤温度</div>
                </div>
                <div class="box-item " @click="openChart('trsd', rightData.sqxqData.deviceId, 2)">
                    <div class="icons img10"></div>
                    <div class="number">{{
                        rightData.sqxqData.soilHumidity ? rightData.sqxqData.soilHumidity.toFixed(2) : '0' }}</div>
                    <div class="text">土壤湿度</div>
                </div>
                <div class="box-item" @click="openChart('trph', rightData.sqxqData.deviceId, 2)">
                    <div class="icons img11"></div>
                    <div class="number">{{ rightData.sqxqData.soilPh ? rightData.sqxqData.soilPh : '0' }}</div>
                    <div class="text">土壤PH</div>
                </div>
                <div class="box-item" @click="openChart('trec', rightData.sqxqData.deviceId, 2)">
                    <div class="icons img12"></div>
                    <div class="number">{{ rightData.sqxqData.soilEc ? rightData.sqxqData.soilEc.toFixed(2) : '0' }}%</div>
                    <div class="text">土壤EC</div>
                </div>
            </div>
        </div>
        <div class="title">水质信息</div>
        <div class="szxx">
            <div class="box">
                <div class="box-item">
                    <div class="icons img13"></div>
                    <div class="number">{{ rightData.szData.waterTemperature ? rightData.szData.waterTemperature.toFixed(2)
                        : '0' }}℃
                    </div>
                    <div class="text">水温</div>
                </div>
                <div class="box-item ">
                    <div class="icons img14"></div>
                    <div class="number">{{ rightData.szData.do ? rightData.szData.do : '0' }}</div>
                    <div class="text">溶解氧</div>
                </div>
                <div class="box-item">
                    <div class="icons img15"></div>
                    <div class="number">{{ rightData.szData.waterPh ? rightData.szData.waterPh : '0' }}</div>
                    <div class="text">水质PH</div>
                </div>
                <div class="box-item ">
                    <div class="icons img16"></div>
                    <div class="number">{{ rightData.szData.conductivity ? rightData.szData.conductivity : '0' }}</div>
                    <div class="text">电导率</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';

export default {

    //import引入的组件需要注入到对象中才能使用
    components: {},
    data() {
        //这里存放数据
        return {
            qxXx: '',
        };
    },
    props: {
        downListQx: [],
        rightData: {}
    },
    //监听属性 类似于data概念
    computed: {},
    //监控data中的数据变化
    watch: {},
    //方法集合
    methods: {
        downClick(el, pr) {
            this.qxXx = el.tenantName
            this.$listeners.cityChange(el.tenantId)
            
        },

    },
    //生命周期 - 创建完成（可以访问当前this实例）
    created() {

    },
    //生命周期 - 挂载完成（可以访问DOM元素）
    mounted() {

    },
    beforeCreate() { }, //生命周期 - 创建之前
    beforeMount() { }, //生命周期 - 挂载之前
    beforeUpdate() { }, //生命周期 - 更新之前
    updated() { }, //生命周期 - 更新之后
    beforeDestroy() { }, //生命周期 - 销毁之前
    destroyed() { }, //生命周期 - 销毁完成
    activated() { }, //如果页面有keep-alive缓存功能，这个函数会触发
}
</script>
<style lang='scss' scoped>
@function companyW($num) {
    @return ($num * 100)/1920;
}

@function companyH($num) {
    @return ($num * 100)/1080;
}

//@import url(); 引入公共css类
.left {
    width: companyW(480vw);
    height: companyH(909vh);
    padding: companyH(25vh) companyW(22vw);
    background-image: url('../../../assets/overviewImg/biankuang.png');
    background-size: 100% 100%;
    background-repeat: no-repeat;

    .select {
        display: flex;
        justify-content: right;
        margin-bottom: companyH(20vh);
    }

    .title {
        width: companyW(480vw);
        color: #fff;
        font-weight: bold;
        font-size: companyW(22vw);
        text-align: center;
        background-image: url('./../../../assets/overviewImg/title.png');
        background-size: 50% 100%;
        background-position: 50% 0;
        background-repeat: no-repeat;
        margin: companyH(20vh) 0;
    }

    .sqxx,
    .qxxx,
    .szxx {
        border: 1px solid #01C696;
        padding: companyW(10vw);

    }

    .box {
        /* margin: ; */
        /* margin: companyH(30vh) companyH(30vh) companyH(0vh) companyH(30vh); */
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around;
        padding: companyH(30vh) 0;
        .box-item {
            text-align: center;
            width: companyW(90vw);
            height: companyH(80vh);
            margin: companyH(10vh) companyW(10vw);

            .img1 {
                background-image: url("../../../assets/number-page/kqsd.png");
            }

            .img2 {
                background-image: url("../../../assets/number-page/kqsdsd.png");
            }

            .img3 {
                background-image: url("../../../assets/number-page/ygqd.png");
            }

            .img4 {
                background-image: url("../../../assets/number-page/jsl.png");
            }

            .img5 {
                background-image: url("../../../assets/number-page/fs.png");
            }

            .img6 {
                background-image: url("../../../assets/number-page/fx.png");
            }

            .img7 {
                background-image: url("../../../assets/number-page/dqy.png");
            }

            .img9 {
                background-image: url("../../../assets/number-page/trsd.png");
            }

            .img10 {
                background-image: url("../../../assets/number-page/trsdsd.png");
            }

            .img11 {
                background-image: url("../../../assets/number-page/trph.png");
            }

            .img12 {
                background-image: url("../../../assets/number-page/trec.png");
            }

            .img13 {
                background-image: url("../../../assets/number-page/trsd.png");
            }

            .img14 {
                background-image: url("../../../assets/number-page/rjy.png");
            }

            .img15 {
                background-image: url("../../../assets/number-page/szph.png");
            }

            .img16 {
                background-image: url("../../../assets/number-page/ddl.png");
            }

            .icons {
                margin: 0 auto;
                width: companyW(40vw);
                height: companyW(40vw);
                background-repeat: no-repeat;
                background-size: 100% 100%;
            }

            .img8 {
                background-image: url("../../../assets/number-page/fl.png");
                margin: companyW(5vw) auto companyW(5vw);
                width: companyW(30vw);
                height: companyW(30vw);
                background-repeat: no-repeat;
                background-size: 100% 100%;
            }

            .number {
                font-weight: bold;
                margin: 0 0 companyW(10vh) 0;
                color: #03c495;
                font-size: companyW(18vw);
            }

            .text {
                font-weight: bold;
                color: #ffffff;
                font-size: companyW(18vw);
            }
        }


    }
}

.el-dropdown-link {
    font-size: companyH(18vh);
    cursor: pointer;
    color: #fff;
    height: companyH(25vh);
    line-height: companyH(25vh);
    font-size: companyW(16vw);
    font-weight: bold;
    border: 1px solid #01C696;
    padding: companyH(7vh) companyW(20vw);
}

.el-icon-arrow-down {
    margin-left: companyW(10vw);
    font-weight: bold;
    color: rgb(2, 163, 122);
    font-size: 12px;
}

::v-deep .popper-select {
    position: absolute !important;
    top: 35px !important;
    left: 0px !important;
}
</style>