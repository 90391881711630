<template>
  <div class="bottomNav">
    <div  @click="selectHighlight(1)" class="bottomNavItem left">
     <div :class="{ 'reative': index === 1 }" class="text">数字总览</div>  
    </div>
    <div :class="{ 'bottomNav-Highlight-right': index === 2 }" @click="selectHighlight(2)" class="bottomNavItem right">
      <div :class="{ 'reative': index === 2 }" class="text">数字管控</div>
    </div>
  </div>
</template>
  
<script>
export default {
  data() {
    return {
    };
  },
  props:{
    index:1
  },
  methods: {
    selectHighlight(value) {
      this.index = value
      if (this.index === 1) {
        if (this.$route.path !== '/overview') {
          this.$router.push({
            path: '/overview',
          });
        }
      } else {
        if (this.$route.path !== '/totalControl') {
          this.$router.push({
            path: '/totalControl',
          });
        }
      }
    },
  },
  mounted(){
    
  }

};
</script>
  
<style  lang="scss" scoped>
@function companyW($num) {
  @return ($num * 100)/1920;
}

@function companyH($num) {
  @return ($num * 100)/1080;
}

@font-face {
  font-family: Source Han Sans CN;
  src: url(../../../assets/font/np4SotSdlbui.woff);
}

.bottomNav {
  width: companyW(600vw);
  height: companyH(62vh);
  position: relative;
  display: flex;
  justify-content: space-between;

  .bottomNavItem {
    flex: 1;
    font-size: companyH(16vh);
    font-family: Source Han Sans CN;
    font-weight: bold;
    color: #ffffff;
    padding-top: companyH(5vh);
   
  }
  .left{
    text-align: right;
    padding-right: companyW(40vw);
  }
  .text{
    display: inline-block;
    padding: companyH(15vh) companyW(30vw) companyH(5vh);
  }
  .right{
    text-align: left;
    padding-left: companyW(40vw); 

  }
  .reative{
    background-image: url('../../../assets/overviewImg/dibu.png');
    background-size: 100% 100%;
    background-repeat: no-repeat;
  }
}

</style>
  