<template>
  <div class="dyr-service">
    <Title
      class="dyr-service-title"
      njTitle="dingbu"
      typeOf="overviewImg"
      title="台州市现代农事服务中心"
    ></Title>
    <div class="box">
      <totalLeft :downListQx="cityList" :rightData="rightData" v-on="$listeners" @cityChange="cityChange" />
      <!-- <div class="map"> -->
        <div  id="dituEcharts" class="mapSize" ></div>
      <!-- </div> -->
      <totalRight
:tenantId="tenantId"
ref="totalRight"
:downList3="downList3"
:mqData2="mqData2"
:yjData="yjData"
v-on="$listeners" />
    </div>
    <bottomNav class="middle-bottomNav"  :index="2"/>
  </div>
</template>

<script>
import Title from './components/title.vue';
import totalLeft from './components/totalLeft.vue';
import totalRight from './components/totalRight.vue';
import bottomNav from './components/bottomNav.vue';
import echarts from 'echarts';
import { getQxdp, getSqxq, getIdName,  getPestMonitoringByName, getAlarmInfo, getUserByParentId,getWaterQuality } from '../../api/numberApi/index';
export default {
  components: {
    Title,
    totalLeft,
    bottomNav,
    totalRight
  },
  watch:{
  },
  data() {
    return {
      allcityData:[],
      tenantId:0,
      cityList: [],
      quData:[],
      shiData:[],
      shengData:[],
      scatterData:[],
      option:null,
      myChart:null,
      downListQx: [],
      downList1: [],
      //虫情监测信息
      mqData2: [],
      //虫情下拉框数据
      downList3: [],
      rightData: {
        //气象信息数据
        qxData: {},
        //墒情信息数据
        sqxqData: {},
        //水质信息
        szData:{}

      },
    };
  },
  created() {
    this.cityChange();
    // this.getData()

  },

  methods: {
    getDitu() {

      let _this = this;
      _this.myChart = this.$echarts.init(
        document.getElementById('dituEcharts')
      );
      var uploadedDataURL =
        'https://nsfwzx1.oss-cn-hangzhou.aliyuncs.com/202401290384111.json';
      // 散点图数据

      let serData = [];
      // 散点图配置
      this.scatterData.forEach(item => {
        serData.push({
          name: `${ item.name }  ${ item.num }`,
          type: 'effectScatter',
          coordinateSystem: 'geo',
          effectType: 'ripple',
          showEffectOn: 'render',
          // 散点样式
          rippleEffect: {
            period: 1,
            scale: 2,
            brushType: 'fill',
          },
          // 散点大小 自定义散点图片
          symbol: `image://${ item.img }`,
          symbolSize: [ 35, 35 ],
          hoverAnimation: true,
          // 标志的样式
          itemStyle: {
            normal: {
              color: 'red',
              shadowBlur: 10,
              shadowColor: '#333',
            },
          },
          label: {
            normal: {
              formatter: '{b}',
              position: 'top',
              show: true,
              color: '#fff',
              fontSize: 14,
              distance: -2,
              backgroundColor: {
                type: 'linear',
                x: 1,
                y: 1,
                r: 0.5,
                colorStops: [
                  {
                    offset: 0,
                    color: item.color[0],
                  },
                  {
                    offset: 1,
                    color: item.color[1],
                  },
                ],
              },
              padding: 5,
            },
          },
          zlevel: 99,
          data: item.data,
        });
      });
      _this.option = {
        // backgroundColor: "#020933",
        legend: {
          show: true,
          left: '5%',
          bottom: '5%',
          orient: 'vertical',
          itemHeight: 30, //修改icon图形大小
          itemWidth: 30, //修改icon图形大小
          textStyle: {
            color: '#fff',
            fontSize: 24,
          },
        },
        geo: [
          {
            map: 'js',
            roam: true, //是否允许缩放
            zoom: 1.1, //默认显示级别
            scaleLimit: {
              min: 0.5,
              max: 3,
            }, //缩放级别
            itemStyle: {
              normal: {
                areaColor: '#3894ec',
                borderColor: '#3fdaff',
                borderWidth: 2,
                shadowColor: 'rgba(63, 218, 255, 0.5)',
                shadowBlur: 30,
              },
              emphasis: {
                areaColor: '#2B91B7',
              },
            },
            label: {
              show: true,
              color: '#ffffff',
              emphasis: {
                color: '#020933',
              },
            },
            tooltip: {
              show: false,
            },
          },
        ],
        // 散点图配置
        series: serData,
      };
      $.get(uploadedDataURL, function (json) {
        echarts.registerMap('js', json);
        console.log(_this.option);

        _this.myChart.setOption(_this.option);

        _this.myChart.on('click', params => {
          console.log(params,'params');
          if (params.data.tId != null) {
            const targetObj = _this.allcityData.find(
              obj => obj.tenantName == params.data.name
            );
            sessionStorage.setItem('ID', targetObj.id);
            sessionStorage.setItem('tenantId', targetObj.tenantId);
            localStorage.setItem('address', targetObj.tenantName);

            const mapPoint = {
              longitude: targetObj.longitude,
              latitude: targetObj.latitude,
              name: targetObj.tenantName,
            };
            localStorage.setItem('key', JSON.stringify(mapPoint));
            _this.$router.push('/');
          }
        });
      });
    },
    //切换城市
    cityChange(id) {
      if (id) {
        this.tenantId = id;
        this.$refs['totalRight'].getData(id);
        this.getQxData(id);
        this.getData(id);
      } else {
        getUserByParentId({ id: sessionStorage.getItem('ID') }).then(res => {
          if (res.code == 1) {
            this.allcityData = res.data;
            res.data.forEach(item => {
              if (item.centerLevel == 3) {
                this.quData.push({
                  name: item.tenantName,
                  value: [ item.longitude, item.latitude ],
                  tId: item.tenantId,
                });
              } else if (item.centerLevel == 2) {
                this.shiData.push({
                  name: item.tenantName,
                  value: [ item.longitude, item.latitude ],
                  tId: item.tenantId,
                });
              } else {
                this.shengData.push({
                  name: item.tenantName,
                  value: [ item.longitude, item.latitude ],
                  tId: item.tenantId,
                });
              }
            });
            this.scatterData = [
              {
                name: '省级农事服务中心',
                num: this.shengData.length,
                color: [ '#f16c08', '#7f3710' ],
                img: '//nsfwzx1.oss-cn-hangzhou.aliyuncs.com/202401307865黄.png',
                data: this.shengData,
              },
              {
                name: '区级农事服务中心',
                num: this.quData.length,
                color: [ '#62dc34', '#23976e' ],
                img: '//nsfwzx1.oss-cn-hangzhou.aliyuncs.com/202401308611绿.png',
                data: this.quData,
              },
              {
                name: '农事服务中心',
                num: this.shiData.length,
                color: [ '#07d0bf', '#0f93a3' ],
                img: '//nsfwzx1.oss-cn-hangzhou.aliyuncs.com/202401306029蓝.png',
                data: this.shiData,
              },
            ];
            this.getDitu();
            this.cityList = res.data.filter(item => {
              if(item.level==0&&item.tenantId){
                return item;
              }
            });
            this.tenantId = this.cityList[0].tenantId;
            this.$refs['totalRight'].getData(this.tenantId);
            console.log(this.tenantId,'');
            this.getQxData(this.cityList[0].tenantId);
            this.getData(this.cityList[0].tenantId);
          }
        });
      }
    },
    async getQxData(id) {
      //获取气象站名称和设备id
      await getIdName({ tenantId: id,category:1 }).then(res => {
        if (res.code == 1 && res.data.length) {
          this.downListQx = res.data.map(item => {
            return {
              label: item.deviceName,
              value: item.deviceId
            };
          });
          this.downQxValue = res.data[0].deviceId;
        }
      });
      //获取虫情监测点
      await getIdName({ tenantId: id,category:3 }).then(res => {
        if (res.code == 1 && res.data.length) {
          this.downList3 = res.data.map(item => {
            return {
              label: item.deviceName,
              value: item.deviceId
            };
          });
        }
      });
      await getQxdp(this.downQxValue,{ tenantId:id }).then(res => {
        if (res.code == 1) {
          this.rightData.qxData = res.data;
          console.log(this.rightData.qxData);
        }
      });
      // 获取所有土壤监测点名称
      await getIdName({ tenantId: id,category:2 }).then(res => {
        if (res.code == 1) {
          this.downList1 = res.data.map(item => {
            return {
              label: item.deviceName,
              value: item.deviceId
            };
          });
        }
      });
      //获取墒情信息
      await getSqxq(this.downList1[0].value,{ tenantId:id }).then(res => {
        if (res.code == 1) {
          this.rightData.sqxqData = res.data;
        }
      });
      // 获取水质信息
      await getWaterQuality({ tenantId:id }).then(res => {
        if (res.code == 1) {
          this.rightData.szData = res.data;
        }
      });
      //根据监测点名称获取虫情监测信息
      await getPestMonitoringByName(this.downList3[0].value,{ tenantId:id }).then(res => {
        if (res.code == 1) {
          this.mqData2 = this.splitArray(res.data, 2);
        }
      });

    },
    //获取没有参数依赖的接口数据
    async getData(id) {
      getAlarmInfo({ tenantId: id }).then(res => {
        if (res.code == 1) {
          this.yjData = res.data;
        }
      });
    },
    // array需要拆分的数组
    splitArray(array, size) {
      let data = [];
      for (let i = 0; i < array.length; i += size) {
        data.push(array.slice(i, i + size));
      }
      return data;
    },
  },
};
</script>

<style lang="scss" scoped>
@function companyW($num) {
  @return ($num * 100)/1920;
}

@function companyH($num) {
  @return ($num * 100)/1080;
}

.dyr-service {
  position: relative;
  width: companyW(1920vw);
  height: companyH(1080vh);
  box-sizing: border-box;
  background-image: url(../../assets/overviewImg/bgc.jpg);
  background-color: rgba(8, 27, 25);
  background-size: cover;
  background-repeat: no-repeat;

  .box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 companyW(31vw);

  }

  .dyr-service-title {
    padding-top: companyH(8vh);
    width: companyW(1820vw);
    height: companyH(70vh);
    margin: 0 auto;
    background-size: contain;
    background-repeat: no-repeat;
  }

  .dyr-service-content {
    display: flex;
    justify-content: flex-start;
    align-items: center;

    .dyr-service-left {
      width: companyW(482vw);
      height: companyH(978vh);
      margin-left: companyW(20vw);

      .dyr-service-service {
        margin-top: companyH(21vh);
      }
    }

    .dyr-service-right {
      margin-right: companyW(20vw);
      height: companyH(978vh);

      .right_fin {
        margin-top: companyH(21vh);
      }
    }
  }

  .middle-bottomNav {
    position: absolute;
    bottom: 0;
    left: companyW(632vw);
    background-image: url(../../assets/overviewImg/dibubgc.png);
    background-size: 100% 100%;
    background-repeat: no-repeat;
  }
}
#dituEcharts{
  width: companyW(800vw);
  height: companyH(800vh);

}
</style>
